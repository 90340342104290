<template>
  <div>
    <v-container v-if="currentFolder">
      <h6>
        <span>Current Folder: {{ currentFolder.name }}</span
        >&nbsp;<a class="blue--text" @click="openFolder(currentFolder.parentId)"
          >Back to Top
        </a>
      </h6>
    </v-container>
    <div>
      <v-btn
        color="orange-bg white--text"
        small
        @click="createFolderDialog = true"
        v-if="
          permissions &&
            permissions.fileLibrary &&
            permissions.fileLibrary.canCreateFolder
        "
      >
        Create Folder
      </v-btn>

      <v-btn
        color="red white--text ml-2"
        small
        @click="deleteFolderDialog = true"
        v-if="
          currentFolder &&
            permissions &&
            permissions.fileLibrary &&
            permissions.fileLibrary.canDeleteFolder
        "
      >
        Delete Folder
      </v-btn>

      <!-- <p>Create Folder</p> -->
      <v-dialog v-model="createFolderDialog" width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Create Folder
          </v-card-title>

          <v-card-text>
            <v-text-field
              class="mt-4"
              v-model="newFolderName"
              color="#F06835"
              outlined
              dense
              label="Folder Name"
            ></v-text-field>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="createFolder">
              Create
            </v-btn>

            <v-btn text @click="createFolderDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteFolderDialog" width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Delete Folder
          </v-card-title>

          <v-card-text>
            <p class="h5">
              Are you sure you want to delete this folder? All Folders below
              this folder will also be deleted.
            </p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="deleteFolder">
              Delete
            </v-btn>

            <v-btn text @click="deleteFolderDialog = false">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="deleteFileDialog" width="500">
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Delete File
          </v-card-title>

          <v-card-text>
            <p class="h5">
              Are you sure you want to delete {{ fileNameToDelete }} file?
            </p>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="deleteFile">
              Delete
            </v-btn>

            <v-btn text @click="hideDeleteFileDialog()">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <v-container>
      <v-row v-if="folders.length > 0">
        <v-col
          md="2"
          sm="3"
          cols="4"
          v-for="(item, i) in folders"
          :key="'c' + i"
        >
          <div>
            <a class="blue--text" @click="openFolder(item.id)">
              <v-icon large color="yellow">mdi-folder</v-icon>
              <p>{{ item.name }}</p>
            </a>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="files.length > 0">
      <p>Files:</p>
      <v-row>
        <v-col cols="12" sm="6" v-for="(item, i) in files" :key="'f' + i">
          <div>
            <p>
              <v-icon large>mdi-file</v-icon>
              <a class="blue--text" target="_blank" :href="item.fileUrl"
                >{{ item.fileName }}
              </a>
            </p>
            <v-btn
              color="red white--text ml-2"
              small
              @click="showDeleteFileDialog(item)"
              v-if="
                currentFolder &&
                  permissions &&
                  permissions.fileLibrary &&
                  permissions.fileLibrary.canDeleteFile
              "
            >
              Delete
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <v-row v-if="currentFolder">
      <v-col cols="12" md="4">
        <MediaUploader
          class="pb-5"
          uploadType="file"
          :key="attachment.id"
          :id="`multiFilePost_${attachment.id}`"
          :index="attachment.id"
          :accept="accept"
          :multiple="false"
          :extensions="extensions"
          :sizeImage="10485760"
          :sizeVideo="104857600"
          :fileId.sync="attachment.fileId"
          :file-url.sync="attachment.url"
          :isUploading.sync="isFileUploading"
          @onMultipleUploaded="onMultipleAddFile"
          @onChange="onChange"
          @onDeleteFile="onDeleteFile"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  GET_QUERY,
  POST_DATA,
  PUT_DATA,
  DELETE,
  API_FOLDERS
} from "@/core/store/crud.module";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { mdiAccountEditOutline, mdiFolder, mdiFile } from "@mdi/js";
import MediaUploader from "@/view/components/imageUploader/mediaUploader.vue";
// import { required } from "vuelidate/lib/validators";
import permissionsHelper from "@/core/helpers/permissionsHelper";

export default {
  components: {
    MediaUploader
  },
  data: () => ({
    isLoading: false,
    statusProcessing: false,
    accountEditIcon: mdiAccountEditOutline,
    folderIcon: mdiFolder,
    fileIcon: mdiFile,
    currentFolder: null,
    createFolderDialog: false,
    deleteFolderDialog: false,
    deleteFileDialog: false,
    fileIdToDelete: "",
    newFolderName: "",
    folders: [],
    files: [],
    newFolder: null,
    attachmentsObj: [{ id: 1, fileId: null, url: null }],
    attachment: { id: 1, fileId: null, url: null },
    isFileUploading: false,
    permissions: {}
  }),
  props: {
    id: {
      type: [Number, String],
      default: 0
    }
  },
  watch: {},
  async created() {
    await permissionsHelper.getPermissions().then(this.getPermissions);
  },
  async mounted() {
    this.isLoading = true;

    await this.$store.dispatch(SET_BREADCRUMB, [{ title: "YN Team Docs" }]);

    if (this.id > 0) await this.getFolder();
    else await this.getFolders();

    this.isLoading = false;
  },
  methods: {
    getPermissions(permissionsObj) {
      this.permissions = permissionsObj;
    },
    async openFolder(folderId) {
      this.currentFolder = null;

      if (folderId > 0) {
        this.$router.push({
          name: "FileLibraryFolder",
          params: { id: folderId }
        });

        this.id = folderId;

        await this.getFolder();
      } else {
        if (this.id > 0) {
          this.$router.push({
            name: "FileLibraryFolders"
          });

          this.id = 0;
        }

        await this.getFolders();
      }
    },
    async deleteFolder() {
      let self = this;
      this.$store
        .dispatch(DELETE, {
          listName: API_FOLDERS,
          id: this.id
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent = "Folder has been deleted";

            if (self.currentFolder.parentId) {
              self.openFolder(self.currentFolder.parentId);
            } else {
              self.openFolder(0);
            }
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }

          self.deleteFolderDialog = false;
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });
        });
    },

    async showDeleteFileDialog(file) {
      this.deleteFileDialog = true;
      this.fileIdToDelete = file.fileUrl;
      this.fileNameToDelete = file.fileName;
    },

    hideDeleteFileDialog() {
      this.deleteFileDialog = false;
      this.fileIdToDelete = "";
      this.fileNameToDelete = "";
    },

    async deleteFile() {
      let index = this.files.findIndex(
        elem => elem.fileUrl == this.fileIdToDelete
      );
      this.files.splice(index, 1);
      this.loading = true;

      await this.updateFolder();

      this.deleteFileDialog = false;
      this.fileIdToDelete = "";
      this.fileNameToDelete = "";

      this.loading = false;

      this.$snackbar.showMessage({
        content: this.snackbarContent,
        color: this.snackbarColor
      });

      // let self = this;
      // this.$store
      //   .dispatch(DELETE, {
      //     listName: API_FILES,
      //     id: this.fileIdToDelete
      //   })
      //   .then(response => {
      //     if (response.status >= 200 && response.status <= 204) {
      //       self.snackbarContent = "File has been deleted";

      //       if (self.currentFolder.parentId) {
      //         self.openFolder(self.currentFolder.parentId);
      //       } else {
      //         self.openFolder(0);
      //       }
      //     } else {
      //       self.snackbarContent = response.data;
      //       self.snackbarColor = "red";
      //     }

      //     self.deleteFileDialog = false;
      //     self.fileIdToDelete = "";
      //     self.fileNameToDelete = "";

      //     self.$snackbar.showMessage({
      //       content: self.snackbarContent,
      //       color: self.snackbarColor
      //     });
      //   });
    },
    async createFolder() {
      let newFolder = {
        name: this.newFolderName
      };
      if (this.id > 0) newFolder.parentId = this.id;

      let self = this;
      this.$store
        .dispatch(POST_DATA, {
          listName: API_FOLDERS,
          data: newFolder
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent = "Folder has been created";

            // self.$router.push({
            //   name: "FileLibraryFolder",
            //   params: { id: this.id }
            // });

            this.openFolder(this.id);
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }

          self.createFolderDialog = false;
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });
        });
    },

    async updateFolder() {
      let updatedFolder = this.currentFolder;

      if (!updatedFolder) return;

      updatedFolder.folders = this.folders;
      updatedFolder.files = this.files;

      // if (this.attachment && this.attachment.fileId) {
      //   updatedFolder.files.push({ id: this.attachment.fileId });
      // }

      // updatedFolder.parentId = this.id;

      let self = this;
      this.$store
        .dispatch(PUT_DATA, {
          listName: `${API_FOLDERS}/${updatedFolder.id}`,
          data: updatedFolder
        })
        .then(response => {
          if (response.status >= 200 && response.status <= 204) {
            self.snackbarContent = "Folder has been updated";
          } else {
            self.snackbarContent = response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });

          this.attachment = { id: 1, fileId: null, url: null };
          this.openFolder(this.id);
        });
    },
    async getFolder() {
      this.loading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_FOLDERS,
          id: this.id
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.currentFolder = response.data;

            this.folders = this.currentFolder.folders.$values;
            this.files = this.currentFolder.files.$values;

            this.folders.forEach(element => {
              element.editDialog = false;
              element.deleteDialog = false;
            });

            this.files.forEach(element => {
              element.editDialog = false;
              element.deleteDialog = false;
            });
          } else {
            this.$snackbar.showMessage({
              content:
                response.data ||
                "Something went wrong! Cannot receive a folder information.",
              color: "red"
            });
          }
          this.loading = false;
        });
    },

    async getFolders() {
      this.loading = true;

      let apiEndpoint = API_FOLDERS;
      if (this.id > 0) apiEndpoint = `${API_FOLDERS}/${this.id}`;
      await this.$store
        .dispatch(GET_QUERY, {
          listName: apiEndpoint
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            let items = response.data.$values;
            items.forEach(element => {
              element.editDialog = false;
              element.deleteDialog = false;
            });

            this.folders = items;
            this.files = [];
          } else {
            this.$snackbar.showMessage({
              content:
                response.data ||
                "Something went wrong! Cannot receive a list of folders.",
              color: "red"
            });
          }
          this.loading = false;
        });
    },

    onMultipleAddFile(files) {
      for (var i = 1; i < files.length; i++) {
        let max = Math.max(...this.attachmentsObj.map(i => i.id));
        this.attachmentsObj.push({
          id: (max += 1),
          url: files[i].fileUrl,
          fileId: files[i].id
        });

        this.files.push({ id: files[i].id, fileUrl: files[i].fileUrl });
      }
      let max = Math.max(...this.attachmentsObj.map(i => i.id));
      this.attachmentsObj.push({ id: (max += 1), url: null });
      this.$nextTick(() => files.forEach(f => this.onChange(f)));

      this.updateFolder();
    },
    onChange(file) {
      if (file.fileUrl) {
        var found = this.attachmentsObj.find(f => f.url == file.fileUrl);
        if (found) found.fileId = file.id;

        this.files.push({ id: file.id });
        this.updateFolder();
      }
    },
    onDeleteFile(fileData) {
      let index = this.attachmentsObj.findIndex(f => f.id === fileData.index);
      this.attachmentsObj.splice(index, 1);
    }
  },
  computed: {
    accept() {
      return "*/*";
    },
    extensions() {
      return "gif,jpg,jpeg,png,webp,mp4,mov,avi,mkv,mpg,vob,wmv,m4v,asf,srt,pdf,doc,docx";
    }
  }
};
</script>
